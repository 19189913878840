
import { computed, defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import CModal from "@/components/modal.vue";
import { Specialty } from "@/store/specialty/types";

const VSelectSpecialty = defineComponent({
  name: "VSelectSpecialty",
  components: { CModal },
  setup() {
    const store = useStore();
    const router = useRouter();

    const specialist = computed(() =>
      store.state.specialist.list.find((specialist) => specialist.id === Number(router.currentRoute.value.params._id))
    );

    const loading = reactive({ submit: false });
    const show = reactive({ form: true, password: false });

    function handleClose() {
      show.form = false;
      router.back();
    }

    function handleSelectSpecialty(specialty: Specialty) {
      show.form = false;
      router.replace({
        name: "consultation-establishment",
        params: { specialty: specialty.nm_especialidade?.toLowerCase().replaceAll(" ", "-") },
        query: { ...router.currentRoute.value.query, sy: specialty.id, st: specialist.value?.id },
      });
    }

    if (!specialist.value) handleClose();

    return { specialist, loading, show, handleSelectSpecialty, handleClose };
  },
});

export default VSelectSpecialty;
